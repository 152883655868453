import LoadingScreen from 'components/LoadingScreen';
import ApplicationGuard from 'guards/ApplicationGuard';
import AuthGuard from 'guards/AuthGuard';
import GuestGuard from 'guards/GuestGuard';
import AppLayout from 'layouts/app';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import MainLayout from 'layouts/main';
import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  const isApp = pathname.includes('/app');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isApp && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <AppLayout currentPage='adminOverview' />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/admin/applications' replace /> }, // login success page
        { path: 'overview', element: <AdminOverview /> },
        { path: 'users', element: <UserList /> },
        { path: 'users/create', element: <UserCreate /> },
        { path: 'users/:userId/update', element: <UserCreate /> },
        { path: 'account', element: <Account /> },
        { path: 'applications', element: <ApplicationList /> },
        { path: 'billing', element: <div>Billing</div> },
        { path: 'settings', element: <div>Settings</div> },
      ],
    },
    {
      path: 'applications/:appId',
      element: (
        <AuthGuard>
          <ApplicationGuard>
            <AppLayout currentPage='applications' />
          </ApplicationGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='overview' replace={false} /> },
        { path: 'overview', element: <RevenueManagementOverview /> },
        {
          path: 'users',
          element: <AppUserList />,
        },
        { path: 'users/:userId', element: <AppUserDetail /> },
        {
          path: 'transactions',
          element: <TransactionList />,
        },
        {
          path: 'transactions/:transactionId',
          element: <TransactionDetail />,
        },
        {
          path: 'charts',
          children: [
            { element: <Navigate to='revenue' replace={false} /> },
            { path: 'revenue', element: <RevenueChart /> },
            { path: 'ltv-per-paying-users', element: <LTVPerPayingUsersChart /> },
            { path: 'ltv-per-all-users', element: <LTVPerAllUsersChart /> },
            { path: 'mrr', element: <MRRChart /> },
            { path: 'churn', element: <ChurnChart /> },
            { path: 'conversion-to-paying', element: <ConversionToPayingChart /> },
            { path: 'initial-conversion', element: <InitialConversionChart /> },
            { path: 'trial-conversion', element: <TrialConversionChart /> },
            { path: 'active-trials', element: <ActiveTrialsChart /> },
            { path: 'auto-renew-status', element: <AutoRenewStatusChart /> },
            { path: 'subscription-retention', element: <SubscriptionRetentionChart /> },
            { path: 'subscription-events', element: <SubscriptionEventsChart /> },
          ],
        },
        {
          path: 'notifications',
          element: <AppNotifications />,
        },
        { path: 'notifications/new', element: <NewAppNotification /> },
        {
          path: 'products',
          element: <Products />,
        },
        { path: 'products/new', element: <NewProduct /> },
        { path: 'products/:productId', element: <NewProduct update /> },
        {
          path: 'mailing',
          element: <AppMailing />,
        },
        { path: 'mailing/new', element: <NewAppMailing /> },
        {
          path: 'webhooks',
          element: <Webhooks />,
        },
        { path: 'webhooks/new', element: <NewWebhook /> },
        { path: 'settings', element: <RevenueManagementSettings /> },
      ],
    },

    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ path: '/', element: <LandingPage /> }],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const Register = Loadable(lazy(() => import('pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('pages/authentication/VerifyCode')));

// Admin
const AdminOverview = Loadable(lazy(() => import('pages/admin/Overview')));
const UserList = Loadable(lazy(() => import('pages/admin/users/UserList')));
const UserCreate = Loadable(lazy(() => import('pages/admin/users/UserCreate')));
const Account = Loadable(lazy(() => import('pages/admin/account/Account')));
const ApplicationList = Loadable(lazy(() => import('pages/admin/applications/ApplicationList')));

// Revenue Management
const RevenueManagementOverview = Loadable(lazy(() => import('pages/application/revenue-management/Overview')));
const AppUserList = Loadable(lazy(() => import('pages/application/user/UserList')));
const AppNotifications = Loadable(lazy(() => import('pages/application/monetization/notification/NotificationList')));
const NewAppNotification = Loadable(lazy(() => import('pages/application/monetization/notification/NewNotification')));
const AppMailing = Loadable(lazy(() => import('pages/application/monetization/mailing/MailingList')));
const NewAppMailing = Loadable(lazy(() => import('pages/application/monetization/mailing/NewMailing')));
const Webhooks = Loadable(lazy(() => import('pages/application/webhook/WebhookList')));
const NewWebhook = Loadable(lazy(() => import('pages/application/webhook/NewWebhook')));
const Products = Loadable(lazy(() => import('pages/application/product/ProductList')));
const NewProduct = Loadable(lazy(() => import('pages/application/product/ProductForm')));
const AppUserDetail = Loadable(lazy(() => import('pages/application/user/UserDetail')));
const TransactionList = Loadable(
  lazy(() => import('pages/application/revenue-management/transaction/TransactionList')),
);
const TransactionDetail = Loadable(
  lazy(() => import('pages/application/revenue-management/transaction/TransactionDetail')),
);
const RevenueChart = Loadable(lazy(() => import('pages/application/revenue-management/charts/RevenueChart')));
const LTVPerPayingUsersChart = Loadable(
  lazy(() => import('pages/application/revenue-management/charts/LTVPerPayingUsersChart')),
);
const LTVPerAllUsersChart = Loadable(
  lazy(() => import('pages/application/revenue-management/charts/LTVPerAllUsersChart')),
);
const MRRChart = Loadable(lazy(() => import('pages/application/revenue-management/charts/MRRChart')));
const ChurnChart = Loadable(lazy(() => import('pages/application/revenue-management/charts/ChurnChart')));
const ConversionToPayingChart = Loadable(
  lazy(() => import('pages/application/revenue-management/charts/ConversionToPayingChart')),
);
const InitialConversionChart = Loadable(
  lazy(() => import('pages/application/revenue-management/charts/InitialConversionChart')),
);
const TrialConversionChart = Loadable(
  lazy(() => import('pages/application/revenue-management/charts/TrialConversionChart')),
);
const ActiveTrialsChart = Loadable(lazy(() => import('pages/application/revenue-management/charts/ActiveTrialsChart')));
const AutoRenewStatusChart = Loadable(
  lazy(() => import('pages/application/revenue-management/charts/AutoRenewStatusChart')),
);
const SubscriptionRetentionChart = Loadable(
  lazy(() => import('pages/application/revenue-management/charts/SubscriptionRetentionChart')),
);
const SubscriptionEventsChart = Loadable(
  lazy(() => import('pages/application/revenue-management/charts/SubscriptionEventsChart')),
);
const RevenueManagementSettings = Loadable(lazy(() => import('pages/application/revenue-management/Settings')));

// Common
const LandingPage = Loadable(lazy(() => import('pages/LandingPage')));
const Page500 = Loadable(lazy(() => import('pages/Page500')));
const NotFound = Loadable(lazy(() => import('pages/Page404')));
const Page403 = Loadable(lazy(() => import('pages/Page403')));
