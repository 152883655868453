import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import axios from 'utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  userList: [],
  notifications: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = map(action.payload, (user) => {
        const { firstName, lastName, ...props } = user;
        return {
          ...props,
          name: `${firstName} ${lastName}`,
        };
      });
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
  },
});

export default slice.reducer;
export const { onToggleFollow, deleteUser } = slice.actions;

export function getUserList(customerId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/customers/${customerId}/users`);
      dispatch(slice.actions.getUserListSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
