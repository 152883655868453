import { format, startOfMonth, endOfMonth, endOfYear, startOfYear, startOfWeek } from 'date-fns';

export function getStartDate(dateRange) {
  const today = new Date();

  switch (dateRange) {
    case 'today':
      return format(new Date(today.setDate(today.getDate())), 'yyyy-MM-dd');
    case 'yesterday':
      return format(new Date(today.setDate(today.getDate() - 1)), 'yyyy-MM-dd');
    case 'thisWeek':
      return format(startOfWeek(new Date(today.setDate(today.getDate())), { weekStartsOn: 1 }), 'yyyy-MM-dd');
    case 'pastWeek':
      return format(startOfWeek(new Date(today.setDate(today.getDate() - 7)), { weekStartsOn: 1 }), 'yyyy-MM-dd');
    case 'last7Days':
      return format(new Date(today.setDate(today.getDate() - 7)), 'yyyy-MM-dd');
    case 'last28Days':
      return format(new Date(today.setDate(today.getDate() - 28)), 'yyyy-MM-dd');
    case 'last90Days':
      return format(new Date(today.setDate(today.getDate() - 90)), 'yyyy-MM-dd');
    case 'last12Months':
      return format(new Date(today.setMonth(today.getMonth() - 12)), 'yyyy-MM-dd');
    case 'thisMonth':
      return format(new Date(today.setDate(today.getDate() - today.getDate() + 1)), 'yyyy-MM-dd');
    case 'lastMonth':
      return format(startOfMonth(new Date(today.setMonth(today.getMonth() - 1))), 'yyyy-MM-dd');
    case 'thisYear':
      return format(startOfYear(new Date(today.setDate(today.getDate()))), 'yyyy-MM-dd');
    case 'lastYear':
      return format(startOfYear(new Date(today.setFullYear(today.getFullYear() - 1))), 'yyyy-MM-dd');
    case 'allTime':
      return format(new Date(today.setFullYear(today.getFullYear() - 5)), 'yyyy-MM-dd');
    default:
      return format(new Date(today.setDate(today.getDate() - 90)), 'yyyy-MM-dd');
  }
}

export function getEndDate(dateRange) {
  const today = new Date();

  switch (dateRange) {
    case 'today':
      return format(new Date(today.setDate(today.getDate())), 'yyyy-MM-dd');
    case 'yesterday':
      return format(new Date(today.setDate(today.getDate() - 1)), 'yyyy-MM-dd');
    case 'thisWeek':
      return format(new Date(today.setDate(today.getDate())), 'yyyy-MM-dd');
    case 'pastWeek':
      return format(new Date(today.setDate(today.getDate() - today.getDay())), 'yyyy-MM-dd');
    case 'last7Days':
      return format(new Date(today.setDate(today.getDate())), 'yyyy-MM-dd');
    case 'last28Days':
      return format(new Date(today.setDate(today.getDate())), 'yyyy-MM-dd');
    case 'last90Days':
      return format(new Date(today.setDate(today.getDate())), 'yyyy-MM-dd');
    case 'last12Months':
      return format(new Date(today.setDate(today.getDate())), 'yyyy-MM-dd');
    case 'thisMonth':
      return format(new Date(today.setDate(today.getDate())), 'yyyy-MM-dd');
    case 'lastMonth':
      return format(endOfMonth(new Date(today.setMonth(today.getMonth() - 1))), 'yyyy-MM-dd');
    case 'thisYear':
      return format(endOfYear(new Date(today)), 'yyyy-MM-dd');
    case 'lastYear':
      return format(endOfYear(new Date(today.setFullYear(today.getFullYear() - 1))), 'yyyy-MM-dd');
    case 'allTime':
      return format(new Date(today.setFullYear(today.getFullYear() + 5)), 'yyyy-MM-dd');
    default:
      return format(new Date(today.setDate(today.getDate())), 'yyyy-MM-dd');
  }
}
