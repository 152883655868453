import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.success === false) {
      return Promise.reject(response.data.message);
    } else if (response.data.success === true) {
      return response.data.data;
    } else {
      throw new Error('Something went wrong');
    }
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'),
);

export default axiosInstance;
