import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import applicationsReducer from './slices/admin/applications';
import overviewReducer from './slices/admin/overview';
import userReducer from './slices/admin/users';
import revenueManagementCharts from './slices/revenue-management/charts';
import revenueManagementOverviewReducer from './slices/revenue-management/overview';
import revenueManagementTransactionsReducer from './slices/revenue-management/transactions';
import revenueManagementUsersReducer from './slices/revenue-management/users';
import monetizationNotificationReducer from './slices/monetization/notification';
import monetizationMailingReducer from './slices/monetization/mailing';
import monetizationTargetUserGroupsReducer from './slices/monetization/targetUserGroup';
import webhookReducer from './slices/webhook';
import ProductReducer from './slices/product';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  user: userReducer,
  overview: overviewReducer,
  revenueManagementOverview: revenueManagementOverviewReducer,
  revenueManagementTransactions: revenueManagementTransactionsReducer,
  revenueManagementCharts: revenueManagementCharts,
  applications: applicationsReducer,
  revenueManagementUsers: revenueManagementUsersReducer,
  monetizationNotification: monetizationNotificationReducer,
  monetizationMailing: monetizationMailingReducer,
  monetizationTargetUserGroups: monetizationTargetUserGroupsReducer,
  webhook: webhookReducer,
  product: ProductReducer,
});

export { rootPersistConfig, rootReducer };
