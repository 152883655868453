import usersIcon from '@iconify/icons-ant-design/user-outline';
import overviewIcon from '@iconify/icons-ic/outline-dashboard';
// import billingIcon from '@iconify/icons-ic/outline-price-check';
import { Icon } from '@iconify/react';
// import settingsIcon from '@iconify/icons-ic/outline-settings';
import applicationsIcon from '@iconify/icons-ic/outline-settings-applications';
import { PATH_APP } from 'routes/paths';

// TODO: fetch this from the server l8r
export const APPLICATIONS_MAP = {
  1: 'Tiny Minies',
  2: 'Sevimli Dostlar',
};

const CHART_ROUTES = (appId) => [
  {
    title: 'Revenue',
    path: PATH_APP.application.chart.revenue(appId),
    access: [1, 2],
  },
  {
    title: 'MRR',
    path: PATH_APP.application.chart.mrr(appId),
    access: [1, 2],
  },
  {
    title: 'LTV per Paying Users',
    path: PATH_APP.application.chart.LTVPerPayingUsers(appId),
    access: [1],
  },
  {
    title: 'LTV per All Users',
    path: PATH_APP.application.chart.LTVPerAllUsers(appId),
    access: [1],
  },
  {
    title: 'Churn',
    path: PATH_APP.application.chart.churn(appId),
    access: [1, 2],
  },
  {
    title: 'Conversion to Paying',
    path: PATH_APP.application.chart.conversionToPaying(appId),
    access: [1, 2],
  },
  {
    title: 'Initial Conversion',
    path: PATH_APP.application.chart.initialConversion(appId),
    access: [1, 2],
  },
  {
    title: 'Trial Conversion',
    path: PATH_APP.application.chart.trialConversion(appId),
    access: [1, 2],
  },
  {
    title: 'Active Trials',
    path: PATH_APP.application.chart.activeTrials(appId),
    access: [1, 2],
  },
  {
    title: 'Auto Renew Status',
    path: PATH_APP.application.chart.autoRenewStatus(appId),
    access: [1, 2],
  },
  {
    title: 'Subscription Retention',
    path: PATH_APP.application.chart.subscriptionRetention(appId),
    access: [1],
  },
  {
    title: 'Subscription Events',
    path: PATH_APP.application.chart.subscriptionEvents(appId),
    access: [1, 2],
  },
];

const sidebarConfig = (appId) => ({
  applications: [
    {
      subheader: APPLICATIONS_MAP[appId] || '',
      items: [
        {
          title: 'overview',
          path: PATH_APP.application.overview(appId),
          icon: <Icon icon={overviewIcon} width={20} height={20} />,
        },
        {
          title: 'users',
          path: PATH_APP.application.user.list(appId),
          icon: <Icon icon='lucide:users' width={20} height={20} />,
        },
        {
          title: 'transactions',
          path: PATH_APP.application.transaction.list(appId),
          icon: <Icon icon='ep:operation' width={20} height={20} />,
        },
        {
          title: 'charts',
          path: `/applications/${appId}/charts`,
          icon: <Icon icon='bi:bar-chart-line' width='20' height='20' />,
          children: CHART_ROUTES(appId).filter((route) => route.access.includes(Number(appId))),
        },
        {
          title: 'products',
          path: PATH_APP.application.product.list(appId),
          icon: <Icon icon='mdi:package-variant-closed' width={20} height={20} />,
        },
        // {
        //   title: 'notifications',
        //   path: PATH_APP.application.notification.list(appId),
        //   icon: <Icon icon='material-symbols:notifications-active-outline-rounded' width={20} height={20} />,
        // },
        // {
        //   title: 'mailing',
        //   path: PATH_APP.application.mailing.list(appId),
        //   icon: <Icon icon='ci:mail' width={20} height={20} />,
        // },
        {
          title: 'webhook',
          path: PATH_APP.application.webhook.list(appId),
          icon: <Icon icon='mdi:webhook' width={20} height={20} />,
        },
        // {
        //   title: 'settings',
        //   path: PATH_APP.application.settings(appId),
        //   icon: <Icon icon='ant-design:setting-outlined' width={20} height={20} />,
        // },
      ],
    },
  ],
  adminOverview: [
    {
      subheader: 'Management',
      items: [
        // {
        //   title: 'overview',
        //   path: PATH_APP.admin.overview,
        //   icon: <Icon icon={overviewIcon} width={20} height={20} />,
        // },
        {
          title: 'users',
          path: PATH_APP.admin.users.root,
          icon: <Icon icon={usersIcon} width={20} height={20} />,
        },
        {
          title: 'applications',
          path: PATH_APP.admin.applications,
          icon: <Icon icon={applicationsIcon} width={20} height={20} />,
        },
        // {
        //   title: 'billing',
        //   path: PATH_APP.admin.billing,
        //   icon: <Icon icon={billingIcon} width={20} height={20} />,
        // },
        // {
        //   title: 'settings',
        //   path: PATH_APP.admin.settings,
        //   icon: <Icon icon={settingsIcon} width={20} height={20} />,
        // },
      ],
    },
  ],
});

export default sidebarConfig;
