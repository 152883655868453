import { Helmet } from 'react-helmet-async';

export default function GoogleAnalytics() {
  if (process.env.NODE_ENV !== 'production') {
    return <></>;
  }

  const measurementId = process.env.REACT_APP_GOOGLE_TAGMANAGER_ID;

  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`} />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${measurementId}');
        `}
      </script>
    </Helmet>
  );
}
