import axios from 'utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { getEndDate, getStartDate } from 'utils/formatDateRange';

const initialState = {
  isLoading: false,
  error: false,
  data: [],
};

const slice = createSlice({
  name: 'revenueManagementOverview',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getRevenueManagementOverviewSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export default slice.reducer;

export function getRevenueManagementOverview(appId, dateRange) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/overview?startDate=${getStartDate(dateRange)}&endDate=${getEndDate(dateRange)}`,
      );
      dispatch(slice.actions.getRevenueManagementOverviewSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
