import { Box } from '@mui/material';
import PropTypes from 'prop-types';

Logo.propTypes = {
  sx: PropTypes.object,
};

export function LogoIcon({ sx }) {
  return (
    <Box sx={{ width: 100, height: 100, ...sx }}>
      <img src='/static/logo-icon.png' alt='about-vision' />
    </Box>
  );
}

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 120, height: 40, ...sx }}>
      <img src='/static/logo.png' alt='about-vision' />
    </Box>
  );
}
