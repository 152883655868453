import axios from 'utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { snakeCase } from 'change-case';

const convertEvent2Enum = (searchFilter) => {
  if (['eventSubType', 'eventType'].includes(searchFilter.column)) {
    return { ...searchFilter, value: snakeCase(searchFilter.value) };
  }
  return searchFilter;
};

const initialState = {
  list: {
    isLoading: true,
    error: false,
    data: [],
    count: 0,
  },
  detail: {
    isLoading: true,
    error: false,
    data: {},
    history: [],
  },
};

const slice = createSlice({
  name: 'revenueManagementTransactions',
  initialState,
  reducers: {
    transactionsStartLoading(state) {
      state.list.isLoading = true;
      state.list.data = [];
    },

    transactionsHasError(state, action) {
      state.list.isLoading = false;
      state.list.error = action.payload;
    },

    transactionsSuccess(state, action) {
      state.list.isLoading = false;
      state.list.data = action.payload.transactions;
      state.list.count = action.payload.count;
    },

    transactionByIdStartLoading(state) {
      state.detail.isLoading = true;
      state.detail.data = [];
    },

    transactionByIdHasError(state, action) {
      state.detail.isLoading = false;
      state.detail.error = action.payload;
    },

    transactionByIdSuccess(state, action) {
      const { history, transaction } = action.payload;

      state.detail.isLoading = false;
      state.detail.data = transaction;
      state.detail.history = history;
    },
  },
});

export default slice.reducer;

export function getRevenueManagementTransactions(appId, page, pageSize, search = []) {
  return async (dispatch) => {
    const searchQuery = search.length > 0 ? `&search=${JSON.stringify(search.map(convertEvent2Enum))}` : '';
    dispatch(slice.actions.transactionsStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/transactions?order=eventDate${searchQuery}&ordering=desc&page=${page}&limit=${pageSize}`,
      );

      dispatch(slice.actions.transactionsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.transactionsHasError(error));
    }
  };
}

export function getRevenueManagementTransactionById(appId, transactionId) {
  return async (dispatch) => {
    dispatch(slice.actions.transactionByIdStartLoading());
    try {
      const response = await axios.get(`/revenue-management/${appId}/transactions/${transactionId}`);
      dispatch(slice.actions.transactionByIdSuccess(response));
    } catch (error) {
      dispatch(slice.actions.transactionByIdHasError(error));
    }
  };
}
