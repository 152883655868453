import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { cognitoConfig } from 'config';
import { PATH_AUTH } from 'routes/paths';
import axios from 'utils/axios';

const CUSTOMER_MAP = {
  '35ef53e2-0e6a-4b2d-b5d9-53468f9c7e38': 'Adisebaba',
  '4c5a527f-8db0-4d1a-86f9-c7d96d1bf37d': 'Gamester',
};

export const UserPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.userPoolId,
  ClientId: cognitoConfig.clientId,
});

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  AUTHENTICATE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      app: null,
    };
  },
  APP_INITIALIZE: (state, action) => ({
    ...state,
    app: action.payload,
  }),
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'cognito',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  initializeApp: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback(
    (currentUser) =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            dispatch({
              type: 'AUTHENTICATE',
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });

            reject(err);
          } else {
            const results = {};

            attributes.forEach((attribute) => {
              results[attribute.Name.replace('custom:', '')] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    [],
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(async (err, session) => {
            if (err) {
              reject(err);
            } else {
              const attributes = await getUserAttributes(user);
              const token = session.getIdToken().getJwtToken();
              axios.defaults.headers.common.Authorization = token;

              dispatch({
                type: 'AUTHENTICATE',
                payload: { isAuthenticated: true, user: attributes },
              });

              resolve({
                user,
                session,
                headers: { Authorization: token },
              });
            }
          });
        } else {
          dispatch({
            type: 'AUTHENTICATE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [getUserAttributes],
  );

  const initial = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: 'AUTHENTICATE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initial();
  }, [initial]);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  const login = useCallback(
    (email, password) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });

        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            getSession();
            resolve(data);
          },
          onFailure: (err) => {
            reject(err);
          },
          newPasswordRequired: () => {
            // Handle this on login page for update password.
            resolve({ message: 'newPasswordRequired' });
          },
        });
      }),
    [getSession],
  );

  // same thing here
  const logout = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      dispatch({ type: 'LOGOUT' });
    }
  };

  const register = (email, password, firstName, lastName) =>
    new Promise((resolve, reject) =>
      UserPool.signUp(
        email,
        password,
        [
          { Name: 'email', Value: email },
          { Name: 'name', Value: `${firstName} ${lastName}` },
        ],
        null,
        async (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve();
          window.location.href = PATH_AUTH.login;
        },
      ),
    );

  const resetPassword = () => {};

  const initializeApp = (app) => {
    dispatch({ type: 'APP_INITIALIZE', payload: app });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: {
          displayName: state?.user?.name,
          role: CUSTOMER_MAP[state?.user?.customerId] || 'User',
          ...state.user,
        },
        login,
        register,
        logout,
        resetPassword,
        initializeApp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
