import useAuth from 'hooks/useAuth';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';

export const CUSTOMER_APPLICATION_MAP = {
  1: '4c5a527f-8db0-4d1a-86f9-c7d96d1bf37d',
  2: '35ef53e2-0e6a-4b2d-b5d9-53468f9c7e38',
};

export default function ApplicationGuard({ children }) {
  const { user } = useAuth();
  const { appId } = useParams();

  const hasAccess = CUSTOMER_APPLICATION_MAP[appId] === user.customerId;

  if (!hasAccess) {
    return <Navigate to='/403' />;
  }

  return children;
}
