import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';

const initialState = {
  activeStep: 0,
  languages: [],
  isLoading: false,
  error: false,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getMonetizationMetaSuccess(state, action) {
      const { languages } = action.payload;

      state.isLoading = false;
      state.languages = languages;
    },

    onBackStep(state) {
      state.activeStep -= 1;
    },

    onNextStep(state) {
      state.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.activeStep = goToStep;
    },
  },
});

export default slice.reducer;

export const { onGotoStep, onBackStep, onNextStep } = slice.actions;

export function getMonetizationMeta(appId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/monetization/${appId}/meta`);

      dispatch(slice.actions.getMonetizationMetaSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
