import axios from 'utils/axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  applicationList: [],
  error: null,
  isLoading: true,
};

const slice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    applicationsStartLoading(state) {
      state.isLoading = true;
    },

    applicationsHasError(state, action) {
      state.isLoading = false;
      state.error = action.payload.error;
    },

    applicationsSuccess(state, action) {
      state.isLoading = false;
      state.applicationList = action.payload;
      state.error = null;
    },
  },
});

export default slice.reducer;

export function getApplications(customerId) {
  return async (dispatch) => {
    dispatch(slice.actions.applicationsStartLoading());
    try {
      const response = await axios.get(`/customers/${customerId}/applications`);
      dispatch(slice.actions.applicationsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.applicationsHasError(error));
    }
  };
}
