import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';

const initialState = {
  list: [],
  isLoading: false,
  error: false,
  prices: [],
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    getProductPricesSuccess(state, action) {
      state.isLoading = false;
      state.prices = action.payload;
    },
  },
});

export default slice.reducer;

export function getProducts(appId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/products/${appId}`);

      dispatch(slice.actions.getProductsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(appId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/products/${appId}`);

      dispatch(slice.actions.getProductsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductPrices(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/products/${productId}/prices`);

      dispatch(slice.actions.getProductPricesSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
