import axios from 'utils/axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: {
    isLoading: false,
    error: false,
    data: [],
  },
};

const slice = createSlice({
  name: 'targetUserGroup',
  initialState,
  reducers: {
    targetUserGroupsStartLoading(state) {
      state.list.isLoading = true;
      state.list.data = [];
    },

    targetUserGroupsHasError(state, action) {
      state.list.isLoading = false;
      state.list.error = action.payload;
    },

    targetUserGroupsSuccess(state, action) {
      state.list.isLoading = false;
      state.list.data = action.payload;
    },
  },
});

export default slice.reducer;

export function getTargetUserGroups(appId) {
  return async (dispatch) => {
    dispatch(slice.actions.targetUserGroupsStartLoading());
    try {
      const response = await axios.get(`/monetization/${appId}/target-user-groups`);

      dispatch(slice.actions.targetUserGroupsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.targetUserGroupsHasError(error));
    }
  };
}
