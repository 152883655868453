import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';

const initialState = {
  activeStep: 0,
  dataSource: '',
  integration: '',
  isLoading: false,
  error: false,
  list: [],
  logs: [],
};

const slice = createSlice({
  name: 'webhook',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getWebhooksSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    getWebhookLogsSuccess(state, action) {
      state.isLoading = false;
      state.logs = action.payload;
    },

    onBackStep(state) {
      state.activeStep -= 1;
    },

    setDataSource(state, action) {
      state.dataSource = action.payload;
      state.activeStep += 1;
    },

    setIntegration(state, action) {
      state.integration = action.payload;
      state.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.activeStep = goToStep;
    },
  },
});

export default slice.reducer;

export const { onGotoStep, onBackStep, onNextStep, setDataSource, setIntegration } = slice.actions;

export function getWebhooks(appId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/webhooks/${appId}`);
      dispatch(slice.actions.getWebhooksSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWebhookLogs(dataSourceId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/webhooks/logs/${dataSourceId}`);
      dispatch(slice.actions.getWebhookLogsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
