function path(root, ...params) {
  return `${root}${params}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_APP = '/admin';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_APP = {
  root: ROOTS_APP,
  admin: {
    overview: path(ROOTS_APP, '/overview'),
    account: path(ROOTS_APP, '/account'),
    profile: path(ROOTS_APP, '/profile'),
    settings: path(ROOTS_APP, '/settings'),
    users: {
      root: path(ROOTS_APP, '/users'),
      newUser: path(ROOTS_APP, '/users/create'),
    },
    products: path(ROOTS_APP, '/products'),
    applications: path(ROOTS_APP, '/applications'),
    billing: path(ROOTS_APP, '/billing'),
  },
  application: {
    overview: (appId) => path('/applications/', `${appId}/overview`),
    user: {
      list: (appId) => path('/applications', `/${appId}/users`),
      detail: (appId, userId) => path('/applications', `/${appId}/users/${userId}`),
    },
    transaction: {
      list: (appId) => path('/applications', `/${appId}/transactions`),
      detail: (appId, transactionId) => path('/applications', `/${appId}/transactions/${transactionId}`),
    },
    chart: {
      revenue: (appId) => path('/applications', `/${appId}/charts/revenue`),
      LTVPerPayingUsers: (appId) => path('/applications', `/${appId}/charts/ltv-per-paying-users`),
      LTVPerAllUsers: (appId) => path('/applications', `/${appId}/charts/ltv-per-all-users`),
      mrr: (appId) => path('/applications', `/${appId}/charts/mrr`),
      churn: (appId) => path('/applications', `/${appId}/charts/churn`),
      conversionToPaying: (appId) => path('/applications', `/${appId}/charts/conversion-to-paying`),
      initialConversion: (appId) => path('/applications', `/${appId}/charts/initial-conversion`),
      trialConversion: (appId) => path('/applications', `/${appId}/charts/trial-conversion`),
      activeTrials: (appId) => path('/applications', `/${appId}/charts/active-trials`),
      autoRenewStatus: (appId) => path('/applications', `/${appId}/charts/auto-renew-status`),
      subscriptionRetention: (appId) => path('/applications', `/${appId}/charts/subscription-retention`),
      subscriptionEvents: (appId) => path('/applications', `/${appId}/charts/subscription-events`),
    },
    notification: {
      list: (appId) => path('/applications', `/${appId}/notifications`),
      new: (appId) => path('/applications', `/${appId}/notifications/new`),
    },
    product: {
      list: (appId) => path('/applications', `/${appId}/products`),
      new: (appId) => path('/applications', `/${appId}/products/new`),
    },
    mailing: {
      list: (appId) => path('/applications', `/${appId}/mailing`),
      new: (appId) => path('/applications', `/${appId}/mailing/new`),
    },
    webhook: {
      list: (appId) => path('/applications', `/${appId}/webhooks`),
      new: (appId) => path('/applications', `/${appId}/webhooks/new`),
    },
    settings: (appId) => path('/applications', `/${appId}/settings`),
  },
};
