import axios from 'utils/axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: {
    isLoading: false,
    error: false,
    data: [],
    count: 0,
  },
  detail: {
    isLoading: true,
    error: false,
    data: {},
    events: [],
    transactions: [],
  },
};

const slice = createSlice({
  name: 'revenueManagementUsers',
  initialState,
  reducers: {
    usersStartLoading(state) {
      state.list.isLoading = true;
      state.list.data = [];
    },

    usersHasError(state, action) {
      state.list.isLoading = false;
      state.list.error = action.payload;
    },

    usersSuccess(state, action) {
      state.list.isLoading = false;
      state.list.data = action.payload.users;
      state.list.count = action.payload.count;
    },

    userByIdStartLoading(state) {
      state.detail.isLoading = true;
      state.detail.data = [];
    },

    userByIdHasError(state, action) {
      state.detail.isLoading = false;
      state.detail.error = action.payload;
    },

    userByIdSuccess(state, action) {
      const { events, user, transactions } = action.payload;

      state.detail.isLoading = false;
      state.detail.data = user;
      state.detail.events = events;
      state.detail.transactions = transactions;
    },
  },
});

export default slice.reducer;

export function getRevenueManagementUsers(appId, page, pageSize, search = []) {
  return async (dispatch) => {
    const searchQuery = search.length > 0 ? `&search=${JSON.stringify(search)}` : '';
    dispatch(slice.actions.usersStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/users?order=creationDate${searchQuery}&ordering=desc&page=${page}&limit=${pageSize}`,
      );

      dispatch(slice.actions.usersSuccess(response));
    } catch (error) {
      dispatch(slice.actions.usersHasError(error));
    }
  };
}

export function getRevenueManagementUserById(appId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.userByIdStartLoading());
    try {
      const response = await axios.get(`/revenue-management/${appId}/users/${userId}`);
      dispatch(slice.actions.userByIdSuccess(response));
    } catch (error) {
      dispatch(slice.actions.userByIdHasError(error));
    }
  };
}
