import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';

const initialState = {
  options: {
    startDate: null,
    endDate: null,
    resolution: '',
    filters: { segment: '' },
  },

  meta: {
    isLoading: false,
    error: null,

    mediaSources: [],
    campaigns: [],
    keywords: [],
    apps: [],
    products: [],
    productDurations: [],
  },
  revenue: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    segment: null,
    segments: [],
    summary: null,
    values: [],
  },
  ltvPerPayingUsers: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    segment: null,
    segments: [],
    summary: null,
    values: [],
  },
  ltvPerAllUsers: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    segment: null,
    segments: [],
    summary: null,
    values: [],
  },
  mrr: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    segment: null,
    segments: [],
    summary: null,
    values: [],
  },
  churn: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    segment: null,
    segments: [],
    summary: null,
    values: [],
  },
  conversionToPaying: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    segment: null,
    segments: [],
    summary: null,
    values: [],
  },
  initialConversion: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    segment: null,
    segments: [],
    summary: null,
    values: [],
  },
  trialConversion: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    segment: null,
    segments: [],
    summary: null,
    values: [],
  },
  activeTrials: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    segment: null,
    segments: [],
    summary: null,
    values: [],
  },
  autoRenewStatus: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    values: [],
  },
  subscriptionRetention: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    segment: null,
    segments: [],
    summary: null,
    values: [],
  },
  subscriptionEvents: {
    isLoading: false,
    error: null,

    startDate: null,
    endDate: null,
    resolution: '',
    filters: {},

    segment: null,
    segments: [],
    summary: null,
    values: [],
    extras: {
      segment: null,
      segments: [],
      summary: { average: {}, total: {} },
      values: [],
    },
  },
};

const slice = createSlice({
  name: 'revenueManagementCharts',
  initialState,
  reducers: {
    updateStartDate(state, action) {
      const { chartKey, value } = action.payload;
      state[chartKey].startDate = value;
    },

    updateEndDate(state, action) {
      const { chartKey, value } = action.payload;
      state[chartKey].endDate = value;
    },

    resetFilters(state, action) {
      const { chartKey } = action.payload;

      state[chartKey].filters = {};
    },

    updateFilters(state, action) {
      const { chartKey, ...filters } = action.payload;

      state[chartKey].filters = { ...state[chartKey].filters, ...filters };
    },

    updateResolution(state, action) {
      const { chartKey, value } = action.payload;
      state[chartKey].resolution = value;
    },

    metaChartStartLoading(state) {
      state.meta.isLoading = true;
    },

    metaChartHasError(state, action) {
      state.meta.error = action.payload;
      state.meta.isLoading = false;
    },

    metaChartSuccess(state, action) {
      state.meta.mediaSources = action.payload.mediaSources;
      state.meta.campaigns = action.payload.campaigns;
      state.meta.apps = action.payload.apps;
      state.meta.products = action.payload.products;
      state.meta.productDurations = action.payload.productDurations;
      state.meta.isLoading = false;
    },

    revenueChartStartLoading(state) {
      state.revenue.isLoading = true;
    },

    revenueChartHasError(state, action) {
      state.revenue.isLoading = false;
      state.revenue.error = action.payload;
    },

    revenueChartSuccess(state, action) {
      state.revenue.segment = action.payload.segment;
      state.revenue.segments = action.payload.segments;
      state.revenue.summary = action.payload.summary;
      state.revenue.values = action.payload.values;

      state.revenue.isLoading = false;
    },

    ltvPerPayingUsersChartStartLoading(state) {
      state.ltvPerPayingUsers.isLoading = true;
    },

    ltvPerPayingUsersChartHasError(state, action) {
      state.ltvPerPayingUsers.isLoading = false;
      state.ltvPerPayingUsers.error = action.payload;
    },

    ltvPerPayingUsersChartSuccess(state, action) {
      state.ltvPerPayingUsers.segment = action.payload.segment;
      state.ltvPerPayingUsers.segments = action.payload.segments;
      state.ltvPerPayingUsers.summary = action.payload.summary;
      state.ltvPerPayingUsers.values = action.payload.values;

      state.ltvPerPayingUsers.isLoading = false;
    },

    ltvPerAllUsersChartStartLoading(state) {
      state.ltvPerAllUsers.isLoading = true;
    },

    ltvPerAllUsersChartHasError(state, action) {
      state.ltvPerAllUsers.isLoading = false;
      state.ltvPerAllUsers.error = action.payload;
    },

    ltvPerAllUsersChartSuccess(state, action) {
      state.ltvPerAllUsers.segment = action.payload.segment;
      state.ltvPerAllUsers.segments = action.payload.segments;
      state.ltvPerAllUsers.summary = action.payload.summary;
      state.ltvPerAllUsers.values = action.payload.values;

      state.ltvPerAllUsers.isLoading = false;
    },

    mrrChartStartLoading(state) {
      state.mrr.isLoading = true;
    },

    mrrChartHasError(state, action) {
      state.mrr.isLoading = false;
      state.mrr.error = action.payload;
    },

    mrrChartSuccess(state, action) {
      state.mrr.segment = action.payload.segment;
      state.mrr.segments = action.payload.segments;
      state.mrr.summary = action.payload.summary;
      state.mrr.values = action.payload.values;

      state.mrr.isLoading = false;
    },

    churnChartStartLoading(state) {
      state.churn.isLoading = true;
    },

    churnChartHasError(state, action) {
      state.churn.isLoading = false;
      state.churn.error = action.payload;
    },

    churnChartSuccess(state, action) {
      state.churn.segment = action.payload.segment;
      state.churn.segments = action.payload.segments;
      state.churn.summary = action.payload.summary;
      state.churn.values = action.payload.values;

      state.churn.isLoading = false;
    },

    conversionToPayingChartStartLoading(state) {
      state.conversionToPaying.isLoading = true;
    },

    conversionToPayingChartHasError(state, action) {
      state.conversionToPaying.isLoading = false;
      state.conversionToPaying.error = action.payload;
    },

    conversionToPayingChartSuccess(state, action) {
      state.conversionToPaying.segment = action.payload.segment;
      state.conversionToPaying.segments = action.payload.segments;
      state.conversionToPaying.summary = action.payload.summary;
      state.conversionToPaying.values = action.payload.values;

      state.conversionToPaying.isLoading = false;
    },

    initialConversionChartStartLoading(state) {
      state.initialConversion.isLoading = true;
    },

    initialConversionChartHasError(state, action) {
      state.initialConversion.isLoading = false;
      state.initialConversion.error = action.payload;
    },

    initialConversionChartSuccess(state, action) {
      state.initialConversion.segment = action.payload.segment;
      state.initialConversion.segments = action.payload.segments;
      state.initialConversion.summary = action.payload.summary;
      state.initialConversion.values = action.payload.values;

      state.initialConversion.isLoading = false;
    },

    trialConversionChartStartLoading(state) {
      state.trialConversion.isLoading = true;
    },

    trialConversionChartHasError(state, action) {
      state.trialConversion.isLoading = false;
      state.trialConversion.error = action.payload;
    },

    trialConversionChartSuccess(state, action) {
      state.trialConversion.segment = action.payload.segment;
      state.trialConversion.segments = action.payload.segments;
      state.trialConversion.summary = action.payload.summary;
      state.trialConversion.values = action.payload.values;

      state.trialConversion.isLoading = false;
    },

    activeTrialsChartStartLoading(state) {
      state.activeTrials.isLoading = true;
    },

    activeTrialsChartHasError(state, action) {
      state.activeTrials.isLoading = false;
      state.activeTrials.error = action.payload;
    },

    activeTrialsChartSuccess(state, action) {
      state.activeTrials.segment = action.payload.segment;
      state.activeTrials.segments = action.payload.segments;
      state.activeTrials.summary = action.payload.summary;
      state.activeTrials.values = action.payload.values;

      state.activeTrials.isLoading = false;
    },

    autoRenewStatusChartStartLoading(state) {
      state.autoRenewStatus.isLoading = true;
    },

    autoRenewStatusChartHasError(state, action) {
      state.autoRenewStatus.isLoading = false;
      state.autoRenewStatus.error = action.payload;
    },

    autoRenewStatusChartSuccess(state, action) {
      state.autoRenewStatus.values = action.payload;

      state.autoRenewStatus.isLoading = false;
    },

    subscriptionRetentionChartStartLoading(state) {
      state.subscriptionRetention.isLoading = true;
    },

    subscriptionRetentionChartHasError(state, action) {
      state.subscriptionRetention.isLoading = false;
      state.subscriptionRetention.error = action.payload;
    },

    subscriptionRetentionChartSuccess(state, action) {
      state.subscriptionRetention.segment = action.payload.segment;
      state.subscriptionRetention.segments = action.payload.segments;
      state.subscriptionRetention.summary = action.payload.summary;
      state.subscriptionRetention.values = action.payload.values;

      state.subscriptionRetention.isLoading = false;
    },

    subscriptionEventsChartStartLoading(state) {
      state.subscriptionEvents.isLoading = true;
    },

    subscriptionEventsChartHasError(state, action) {
      state.subscriptionEvents.isLoading = false;
      state.subscriptionEvents.error = action.payload;
    },

    subscriptionEventsChartSuccess(state, action) {
      state.subscriptionEvents.segment = action.payload.eventChart.segment;
      state.subscriptionEvents.segments = action.payload.eventChart.segments;
      state.subscriptionEvents.summary = action.payload.eventChart.summary;
      state.subscriptionEvents.values = action.payload.eventChart.values;

      state.subscriptionEvents.extras = {
        segment: action.payload.subEventChart.segment,
        segments: action.payload.subEventChart.segments,
        summary: action.payload.subEventChart.summary,
        values: action.payload.subEventChart.values,
      };

      state.subscriptionEvents.isLoading = false;
    },
  },
});

export default slice.reducer;

export const { resetFilters, updateResolution, updateStartDate, updateFilters, updateEndDate } = slice.actions;

export function getMetaChart(appId) {
  return async (dispatch) => {
    dispatch(slice.actions.metaChartStartLoading());
    try {
      const response = await axios.get(`/revenue-management/${appId}/charts/meta`);
      dispatch(slice.actions.metaChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.metaChartHasError(error.message));
    }
  };
}

export function getRevenueChart(appId, startDate, endDate, resolution, filterOption) {
  return async (dispatch) => {
    dispatch(slice.actions.revenueChartStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/charts/revenue?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}&filters=${JSON.stringify(
          filterOption,
        )}`,
      );
      dispatch(slice.actions.revenueChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.revenueChartHasError(error.message));
    }
  };
}

export function getLTVPerPayingUsersChart(appId, startDate, endDate, resolution, filterOption) {
  return async (dispatch) => {
    dispatch(slice.actions.ltvPerPayingUsersChartStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/charts/ltv-per-paying-users?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}&filters=${JSON.stringify(
          filterOption,
        )}`,
      );
      dispatch(slice.actions.ltvPerPayingUsersChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.ltvPerPayingUsersChartHasError(error));
    }
  };
}

export function getLTVPerAllUsersChart(appId, startDate, endDate, resolution, filterOption) {
  return async (dispatch) => {
    dispatch(slice.actions.ltvPerAllUsersChartStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/charts/ltv-per-all-users?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}&filters=${JSON.stringify(
          filterOption,
        )}`,
      );
      dispatch(slice.actions.ltvPerAllUsersChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.ltvPerAllUsersChartHasError(error));
    }
  };
}

export function getMRRChart(appId, startDate, endDate, resolution, filterOption) {
  return async (dispatch) => {
    dispatch(slice.actions.mrrChartStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/charts/mrr?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}&filters=${JSON.stringify(
          filterOption,
        )}`,
      );
      dispatch(slice.actions.mrrChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.mrrChartHasError(error.message));
    }
  };
}

export function getChurnChart(appId, startDate, endDate, resolution, filterOption) {
  return async (dispatch) => {
    dispatch(slice.actions.churnChartStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/charts/churn?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}&filters=${JSON.stringify(
          filterOption,
        )}`,
      );
      dispatch(slice.actions.churnChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.churnChartHasError(error.message));
    }
  };
}

export function getConversionToPayingChart(appId, startDate, endDate, resolution, filterOption) {
  return async (dispatch) => {
    dispatch(slice.actions.conversionToPayingChartStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/charts/conversion-to-paying?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}&filters=${JSON.stringify(
          filterOption,
        )}`,
      );
      dispatch(slice.actions.conversionToPayingChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.conversionToPayingChartHasError(error.message));
    }
  };
}

export function getInitialConversionChart(appId, startDate, endDate, resolution, filterOption) {
  return async (dispatch) => {
    dispatch(slice.actions.initialConversionChartStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/charts/initial-conversion?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}&filters=${JSON.stringify(
          filterOption,
        )}`,
      );
      dispatch(slice.actions.initialConversionChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.initialConversionChartHasError(error.message));
    }
  };
}

export function getTrialConversionChart(appId, startDate, endDate, resolution, filterOption) {
  return async (dispatch) => {
    dispatch(slice.actions.trialConversionChartStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/charts/trial-conversion?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}&filters=${JSON.stringify(
          filterOption,
        )}`,
      );
      dispatch(slice.actions.trialConversionChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.trialConversionChartHasError(error.message));
    }
  };
}

export function getActiveTrialsChart(appId, startDate, endDate, resolution, filterOption) {
  return async (dispatch) => {
    dispatch(slice.actions.activeTrialsChartStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/charts/active-trials?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}&filters=${JSON.stringify(
          filterOption,
        )}`,
      );
      dispatch(slice.actions.activeTrialsChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.activeTrialsChartHasError(error));
    }
  };
}

export function getAutoRenewStatusChart(appId) {
  return async (dispatch) => {
    dispatch(slice.actions.autoRenewStatusChartStartLoading());
    try {
      const response = await axios.get(`/revenue-management/${appId}/charts/auto-renew-status`);
      dispatch(slice.actions.autoRenewStatusChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.autoRenewStatusChartHasError(error.message));
    }
  };
}

export function getSubscriptionRetentionChart(appId, startDate, endDate, resolution, filterOption) {
  return async (dispatch) => {
    dispatch(slice.actions.subscriptionRetentionChartStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/charts/subscription-retention?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}&filters=${JSON.stringify(
          filterOption,
        )}`,
      );
      dispatch(slice.actions.subscriptionRetentionChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.subscriptionRetentionChartHasError(error.message));
    }
  };
}

export function getSubscriptionEventsChart(appId, startDate, endDate, resolution, filterOption) {
  return async (dispatch) => {
    dispatch(slice.actions.subscriptionEventsChartStartLoading());
    try {
      const response = await axios.get(
        `/revenue-management/${appId}/charts/subscription-events?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}&filters=${JSON.stringify(
          filterOption,
        )}`,
      );
      dispatch(slice.actions.subscriptionEventsChartSuccess(response));
    } catch (error) {
      dispatch(slice.actions.subscriptionEventsChartHasError(error.message));
    }
  };
}
